
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































































































































































































































































































































































































.my-sidenav {
  position: relative;
  background-color: darken($c-main-primary, 1);

  &::before {
    @include get-all-space;

    content: '';
    right: 100%;
    left: auto;
    width: 50vw;
    background-color: darken($c-main-primary, 1);
  }
}

.my-sidenav__term {
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: $c-white;
  font-size: 1.5rem;

  &:not(:first-child) {
    margin-top: 4rem;
  }
}

.my-sidenav__list__back {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  color: $c-white;
  text-decoration: none;

  svg {
    fill: $c-white;
  }

  &:hover {
    color: $c-gray-dark;
  }
}

.my-sidenav__item,
[class*='my-sidenav__item--'] {
  color: $c-white;
  font-size: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .router-link-active {
    position: relative;
    display: inline-block;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -2rem;
      width: 5px;
      height: 1px;
      background: $c-white;
      transform-origin: 100% 50%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  a {
    color: $c-white;
    font-size: 1.5rem;
    line-height: 1.1;
    text-decoration: none;

    &:hover {
      color: rgb(247, 166, 0);
    }
  }
}

[class*='my-sidenav__item--'][class*='--ean'] {
  margin-top: -1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.2;
  border-top: 1px solid $c-gray-dark;
  border-bottom: 1px solid $c-gray-dark;
}

.other-links-container {
  border-top: 1px solid $c-white;

  ul {
    margin-top: 2em;
    padding: 0;
  }

  .my-sidenav__item {
    list-style-type: none;
    padding: 0;
  }
}
